import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import ButtonBase from "@material-ui/core/ButtonBase"

import EpisodePage from "./EpisodePage"

const useStyles = makeStyles(theme => ({
  paper: {
    // padding: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  button: {
    height: "100%",
    width: "100%",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    justifyContent: "flex-start",
    textAlign: "left",
    padding: theme.spacing(2, 3),
  },
  title: {
    textAlign: "center",
    marginBottom: "1rem",
    fontWeight: "600",
  },
}))

const Episode = props => {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  const { episode, handleEpisodeClick } = props

  return (
    <Paper className={fixedHeightPaper}>
      <ButtonBase
        focusRipple
        className={classes.button}
        onClick={e => handleEpisodeClick(e, episode._id)}
      >
        <Typography
          component="h1"
          variant="h5"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {episode.tag}
        </Typography>
        <Typography component="p" variant="body1" color="inherit">
          {episode.intro}
        </Typography>
      </ButtonBase>
    </Paper>
  )
}

const EpisodeListPage = props => {
  // const { setUnsavedChange } = props
  const { episodeMetadataList, episodeId, handleEpisodeListItemClick } = props

  return (
    <Grid container spacing={3}>
      {episodeId.length === 0 &&
        episodeMetadataList &&
        episodeMetadataList.map((ep, index) => {
          return (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Episode
                episode={ep}
                handleEpisodeClick={handleEpisodeListItemClick}
              />
            </Grid>
          )
        })}
      {episodeId.length > 0 && <EpisodePage episodeId={episodeId} />}
    </Grid>
  )
}

export default EpisodeListPage
