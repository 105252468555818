import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { ThemeProvider, makeStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Drawer from "@material-ui/core/Drawer"
import Box from "@material-ui/core/Box"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import Container from "@material-ui/core/Container"
import Link from "@material-ui/core/Link"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"

import theme from "../components/dashboard/dashboardTheme"
import MainListItems from "../components/dashboard/MainListItems"
import Dashboard from "../components/dashboard/Dashboard"
import EpisodeListPage from "../components/dashboard/EpisodeListPage"
import EpisodesWorkStatus from "../components/dashboard/EpisodesWorkStatus"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://centralquestion.co/">
        Central Question
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "#038FBB",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

const menuItemString = ["Dashboard", "Episodes"]
export default function Wizard() {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [unsavedChange, setUnsavedChange] = useState(false)
  const [episodeId, setEpisodeId] = useState("")
  const [episodeMetadataList, setEpisodeMetadataList] = useState([])
  const [episodeListOpen, setEpisodeListOpen] = useState(false)

  const handleEpisodeListClick = e => {
    handleListItemClick(e, 1)
    setEpisodeListOpen(!episodeListOpen)
    setEpisodeId("")
  }

  const handleEpisodeListItemClick = (e, epid) => {
    selectedIndex !== 1 && setSelectedIndex(1)
    !episodeListOpen && setEpisodeListOpen(true)
    setEpisodeId(epid)
  }

  useEffect(() => {
    async function fetchData() {
      fetch(
        // `https://7tq9a0dvt6.execute-api.us-east-1.amazonaws.com/dev/episodes?metadata=true`,
        `http://localhost:3000/episodes?metadata=true`,
        {
          method: "GET",
          // mode: "no-cors",
        }
      )
        .then(res => res.json())
        .then(res => {
          setEpisodeMetadataList(Array.from(res.result))
        })
        .catch(e => {
          console.log("Error while getting questions!", e)
        })
    }

    fetchData()
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {`${menuItemString[selectedIndex]}${
                episodeId
                  ? " / " +
                    episodeMetadataList.find(ep => ep._id === episodeId).tag
                  : ""
              }`}
            </Typography>
            {selectedIndex === 1 && (
              <EpisodesWorkStatus unsavedChange={unsavedChange} />
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <MainListItems
              episodeMetadataList={episodeMetadataList}
              selectedIndex={selectedIndex}
              episodeId={episodeId}
              setEpisodeId={setEpisodeId}
              episodeListOpen={episodeListOpen}
              handleListItemClick={handleListItemClick}
              handleEpisodeListClick={handleEpisodeListClick}
              handleEpisodeListItemClick={handleEpisodeListItemClick}
            />
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {selectedIndex === 0 && <Dashboard />}
            {selectedIndex === 1 && (
              <EpisodeListPage
                episodeMetadataList={episodeMetadataList}
                episodeId={episodeId}
                handleEpisodeListItemClick={handleEpisodeListItemClick}
                setUnsavedChange={setUnsavedChange}
              />
            )}

            <Box pt={8}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  )
}
