import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import ButtonBase from "@material-ui/core/ButtonBase"
import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered"
import ViewComfyIcon from "@material-ui/icons/ViewComfy"
import Skeleton from "@material-ui/lab/Skeleton"
import Tooltip from "@material-ui/core/Tooltip"
import Fab from "@material-ui/core/Fab"
import { green } from "@material-ui/core/colors"

import QuestionEditor from "./QuestionEditor"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
    width: "100%",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 2,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  iconButton: {
    color: "#ccc",
    transition: "color .5s",
    "&:hover": {
      color: "inherit",
    },
  },
  iconButtonActive: {
    color: "inherit",
  },
  listItem: {
    alignItems: "flex-start",
    border: "1px solid rgba(0,0,0,.1)",
    borderRadius: "2rem",
    cursor: "pointer",
    transition: "background-color .25s",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
    paddingRight: theme.spacing(12),
  },
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 270,
  },
  actionButton: {
    color: "#fff",
  },
  questionNumber: {
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(2),
    flexGrow: 0,
  },
  questionText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&>span": {
      whiteSpace: "nowrap",
    },
  },
  button: {
    display: "block",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    overflow: "hidden",
    height: `calc(100% - ${theme.spacing(4)}px)`,
  },
  questionHeader: {
    // backgroundColor: "#333333",
    backgroundColor: "#6689a2",
    color: "#fff",
    height: theme.spacing(5),
    padding: theme.spacing(0, 2),
  },
  questionId: {
    fontSize: ".75rem",
  },
  questionContent: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "100%",
    textAlign: "left",
    overflowY: "auto",
  },
}))

const EpisodePage = props => {
  const { episodeId } = props
  const [questionList, setQuestionList] = useState([])
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1)
  const [openQuestionEditor, setOpenQuestionEditor] = useState(false)
  const [view, setView] = useState("grid")
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  useEffect(() => {
    async function fetchData() {
      fetch(
        // `https://7tq9a0dvt6.execute-api.us-east-1.amazonaws.com/dev/episodes/questions?epid=${episodeId}`,
        `http://localhost:3000/episodes/questions?epid=${episodeId}`,
        {
          method: "GET",
          // mode: "no-cors",
        }
      )
        .then(res => res.json())
        .then(res => {
          setQuestionList(Array.from(res.result[0].questions))
          setLoading(false)
        })
        .catch(e => {
          console.log("Error while getting questions!", e)
        })
    }
    fetchData()
  }, [episodeId])

  const handleViewButtonClick = viewStyle => {
    setView(viewStyle)
  }

  const handleQuestionClick = index => {
    setSelectedQuestionIndex(index)
    setOpenQuestionEditor(true)
  }

  const handleQuestionEditorClose = () => {
    setOpenQuestionEditor(false)
  }

  return (
    <div className={classes.root}>
      <Fab
        color="primary"
        aria-label="add"
        className={clsx(classes.fab, classes.fabGreen)}
      >
        <AddIcon />
      </Fab>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" p={1}>
        <Tooltip title="List view">
          <IconButton
            size="small"
            aria-label="list view"
            className={clsx(
              classes.iconButton,
              view === "list" && classes.iconButtonActive
            )}
            onClick={() => handleViewButtonClick("list")}
          >
            <FormatListNumberedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Grid view">
          <IconButton
            size="small"
            aria-label="grid view"
            className={clsx(
              classes.iconButton,
              view === "grid" && classes.iconButtonActive
            )}
            onClick={() => handleViewButtonClick("grid")}
          >
            <ViewComfyIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {view === "list" && (
        <List>
          {questionList.map(q => {
            var text = q.statement.replace(/(<([^>]+)>)/gi, "")

            return (
              <ListItem key={q.id} className={classes.listItem}>
                <ListItemText
                  primary={`${q.id}.`}
                  className={classes.questionNumber}
                />
                <ListItemText primary={text} className={classes.questionText} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      )}
      {view === "grid" && (
        <Grid container spacing={3}>
          {questionList.map((q, index) => {
            // var text = q.statement.replace(/(<([^>]+)>)/gi, "")
            return (
              <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                <Paper className={fixedHeightPaper}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.questionHeader}
                    p={2}
                    width="100%"
                  >
                    {!loading ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <Typography
                          component="div"
                          className={classes.questionId}
                        >
                          Question {q.id}
                        </Typography>
                        <Box>
                          <IconButton edge="end" aria-label="edit" size="small">
                            <EditIcon
                              fontSize="small"
                              className={classes.actionButton}
                            />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                          >
                            <DeleteIcon
                              fontSize="small"
                              className={classes.actionButton}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    ) : (
                      <Skeleton width="100%" />
                    )}
                  </Box>
                  <ButtonBase
                    focusRipple
                    className={classes.button}
                    onClick={() => handleQuestionClick(index)}
                  >
                    <Box className={classes.questionContent}>
                      {!loading ? (
                        <Typography
                          component="div"
                          variant="body1"
                          color="inherit"
                          dangerouslySetInnerHTML={{ __html: q.statement }}
                        ></Typography>
                      ) : (
                        <>
                          <Skeleton width="100%" />
                          <Skeleton width="80%" />
                          <Skeleton width="100%" />
                          <Skeleton width="80%" />
                        </>
                      )}
                    </Box>
                  </ButtonBase>
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      )}
      {openQuestionEditor && selectedQuestionIndex > -1 && (
        <QuestionEditor
          question={questionList[selectedQuestionIndex]}
          open={openQuestionEditor}
          handleClose={handleQuestionEditorClose}
        />
      )}
    </div>
  )
}

export default EpisodePage
