import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import DashboardIcon from "@material-ui/icons/Dashboard"

import EpisodesList from "./EpisodesList"

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: 38,
  },
}))

const MainListItems = props => {
  const {
    episodeMetadataList,
    selectedIndex,
    episodeId,
    setEpisodeId,
    episodeListOpen,
    handleListItemClick,
    handleEpisodeListClick,
    handleEpisodeListItemClick,
  } = props
  const classes = useStyles()

  return (
    <>
      <ListItem
        button
        onClick={e => {
          handleListItemClick(e, 0)
          setEpisodeId("")
        }}
        selected={selectedIndex === 0}
      >
        <ListItemIcon className={classes.icon}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <EpisodesList
        episodeMetadataList={episodeMetadataList}
        selectedIndex={selectedIndex}
        episodeListOpen={episodeListOpen}
        episodeId={episodeId}
        handleEpisodeListClick={handleEpisodeListClick}
        handleEpisodeListItemClick={handleEpisodeListItemClick}
      />
    </>
  )
}

export default MainListItems
