import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import NoteIcon from "@material-ui/icons/Note"

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(7),
  },
  icon: {
    minWidth: 38,
  },
}))

const EpisodesList = props => {
  const classes = useStyles()
  const {
    episodeMetadataList,
    selectedIndex,
    episodeListOpen,
    episodeId,
    handleEpisodeListClick,
    handleEpisodeListItemClick,
  } = props

  return (
    <>
      <ListItem
        button
        onClick={e => handleEpisodeListClick(e)}
        selected={selectedIndex === 1}
      >
        <ListItemIcon className={classes.icon}>
          <MenuBookIcon />
        </ListItemIcon>
        <ListItemText primary="Episodes" />
        {episodeListOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={episodeListOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {episodeMetadataList &&
            episodeMetadataList.map((ep, index) => (
              <ListItem
                button
                className={classes.nested}
                key={index}
                selected={episodeId === ep._id}
                onClick={e => handleEpisodeListItemClick(e, ep._id)}
              >
                <ListItemIcon className={classes.icon}>
                  <NoteIcon />
                </ListItemIcon>
                <ListItemText primary={`${ep.tag}`} />
              </ListItem>
            ))}
        </List>
      </Collapse>
    </>
  )
}

export default EpisodesList
