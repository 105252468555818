import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import EditIcon from "@material-ui/icons/Edit"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import clsx from "clsx"

import styles from "../question.module.css"
import MultipleChoice from "../MultipleChoice"
import FillinBlank from "../FillinBlank"
import MultipleResponse from "../MultipleResponse"
import Feedback from "../feedback"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    backgroundColor: "#6689a2",
  },
  title: {
    color: "#fff",
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    "&>*": {
      margin: theme.spacing(2),
    },
  },
  paperRoot: {
    width: "100%",
  },
  previewLabel: {
    color: "#ccc",
    fontSize: "8em",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    opacity: 0.1,
    pointerEvents: "none",
    userSelect: "none",
    zIndex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Question = props => {
  const { question } = props
  const classes = useStyles()

  return (
    <div className={clsx(styles.question, styles.unanswered)}>
      <div className={classes.previewLabel}>Preview</div>
      <div className={styles.questionBody}>
        <div dangerouslySetInnerHTML={{ __html: question.statement }}></div>
        {question.figure && (
          <figure>
            {/* {question.figure.image && (
              <Img
                fluid={question.figure.image.childImageSharp.fluid}
                alt={question.figure.alt || ""}
              />
            )} */}
            {(question.figure.caption || question.figure.credit) && (
              <figcaption>
                {question.figure.caption && (
                  <span className={styles.figCaption}>
                    {question.figure.caption}
                  </span>
                )}
                {question.figure.credit && (
                  <span className={styles.figCredit}>
                    &nbsp;{question.figure.credit}
                  </span>
                )}
              </figcaption>
            )}
          </figure>
        )}
        <div className={styles.choices}>
          {["multiplechoice", "polling"].includes(question.type) && (
            <MultipleChoice
              qid={question.id}
              type={question.type}
              choices={question.choices}
              answer={parseInt(question.answer[0])}
            />
          )}
          {question.type === "fillinblank" && (
            <FillinBlank
              qid={question.id}
              type={question.type}
              answer={question.answer}
            />
          )}
          {question.type === "multipleresponse" && (
            <MultipleResponse
              qid={question.id}
              type={question.type}
              choices={question.choices}
              answer={question.answer}
            />
          )}
        </div>
        <Feedback
          type={question.type}
          feedback={question.feedback}
          stats={{ stat1: 0, stat2: 0 }}
          statsLoading={true}
          source={question.source}
        />
      </div>
    </div>
  )
}

const QuestionEditor = props => {
  const { question, open, handleClose } = props
  const [unsavedChange, setUnsavedChange] = useState(false)
  const classes = useStyles()

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Tooltip title="Close the editor">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" className={classes.title}>
            Question {question.id}
          </Typography>
          <Tooltip title="Save the changes">
            <Button color="inherit" onClick={handleClose}>
              <Badge color="secondary" variant="dot" invisible={!unsavedChange}>
                <Typography>save</Typography>
              </Badge>
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Grid
        container
        direction="row"
        justify="space-between"
        // spacing={3}
        className={classes.container}
      >
        <Grid container item xs={12} sm md={7} alignItems="flex-start">
          <Paper elevation={3} className={classes.paperRoot}>
            <List disablePadding>
              <ListItem button>
                <ListItemText primary="Type" secondary={question.type} />
                <Tooltip title="Update question type">
                  <EditIcon color="action" fontSize="small" />
                </Tooltip>
              </ListItem>
              <Divider />
              <ListItem button>
                <ListItemText
                  primary="Statement"
                  secondary={question.statement.replace(/(<([^>]+)>)/gi, "")}
                />
                <Tooltip title="Update question statement">
                  <EditIcon color="action" fontSize="small" />
                </Tooltip>
              </ListItem>
              <Divider />
              {["multipleresponse", "multiplechoice", "polling"].includes(
                question.type
              ) && (
                <>
                  <ListItem button>
                    <ListItemText
                      primary="Choices"
                      secondary={question.choices.map((c, i) =>
                        i < question.choices.length - 1 ? `${c}, ` : c
                      )}
                    />
                    <Tooltip title="Update choices">
                      <EditIcon color="action" fontSize="small" />
                    </Tooltip>
                  </ListItem>
                  <Divider />
                </>
              )}
              <ListItem button>
                <ListItemText
                  primary="Answer"
                  secondary={question.answer.map((a, i) =>
                    i < question.answer.length - 1 ? `${a}, ` : a
                  )}
                />
                <Tooltip title="Update the answer">
                  <EditIcon color="action" fontSize="small" />
                </Tooltip>
              </ListItem>
              <Divider />
              <ListItem button>
                <ListItemText
                  primary="Feedback"
                  secondary={question.feedback.replace(/(<([^>]+)>)/gi, "")}
                />
                <Tooltip title="Update the feedback">
                  <EditIcon color="action" fontSize="small" />
                </Tooltip>
              </ListItem>
              <Divider />
              <ListItem button>
                <ListItemText
                  primary="Source"
                  secondary={`${question.source.label}${
                    question.source.url ? ", " + question.source.url : ""
                  }`}
                />
                <Tooltip title="Update the source">
                  <EditIcon color="action" fontSize="small" />
                </Tooltip>
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid container item xs sm direction="column">
          <Question question={question} className={classes.paperRoot} />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default QuestionEditor
