import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  textPrimary: {
    // backgroundColor: "rgba(0,0,0,.7)",
    // "&:hover": {
    // backgroundColor: "#000",
    // },
    // padding: theme.spacing(1, 2),
  },
}))

const EpisodesWorkStatus = props => {
  const { unsavedChange } = props

  const classes = useStyles()

  return (
    <Button
      // variant="contained"
      // color="primary"
      color="inherit"
      className={classes.textPrimary}
      disabled={unsavedChange ? false : true}
    >
      Publish
    </Button>
  )
}

export default EpisodesWorkStatus
